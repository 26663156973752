import AutoRenewIcon from "@material-ui/icons/Autorenew";
import EmailIcon from "@material-ui/icons/Email";
import React, {createRef, useEffect, useState} from "react";
import {
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    List,
    Pagination,
    SearchInput,
    SelectInput,
    TextField,
    useDataProvider,
    useMutation,
    useNotify,
    useQuery,
    useRefresh
} from "react-admin";
import {useParams} from "react-router";
import DashboardPageWrapperWithTheme, {DashboardPageWrapperRef} from "../../components/DashboardPageWrapper";
import VKeyListButton from "../../components/VkeyListButton";
import {BASE_URL} from "../../dataProvider";
import {
    ACTION_GET_LIST_GROUP,
    ACTION_RE_GENERATE,
    ACTION_SEND_MAIL
} from "../../dataProvider/activationCodeDataProvider";
import log from "../../utils/log";
import LocalStorage from "../../utils/services/storage";
import ACListActions from "./ACListActions";
import ActivationCodeBulkActions from "./ActivationCodeBulkActions";
import ConfirmDialog, {ConfirmDialogRef} from "./ConfirmDialog";
import EmailFormDialog, {EmailFormDialogRef} from "./EmailFormDialog";

export const downloadFileWithAuth = (tokenPackId: string, fileName: string) => {
    log.info(
        `Downloading private file with access_token tokenPackId=${tokenPackId}, fileName=${fileName}`
    );
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let file =
        BASE_URL +
        "/idm-config/v2/reactive/activation-code/" +
        LocalStorage.instance.getCustomerId() +
        "/download-token-pack-ac-zip/" +
        tokenPackId;

    let headers = new Headers();
    headers.append(
        "Authorization",
        "Bearer " + LocalStorage.instance.getToken()
    );

    fetch(file, {headers})
        .then((response) => response.blob())
        .then((blobby) => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        });
};

const Filters = (props) => {
    const {tokenPackId} = useParams<{ tokenPackId: string }>();

    useEffect(() => {
        log.info("Filter initial.");
    }, []);

    const {data, loading, error} = useQuery({
        type: ACTION_GET_LIST_GROUP,
        resource: "activation-code",
        payload: {id: tokenPackId},
    });

    useEffect(() => {
        log.info(ACTION_GET_LIST_GROUP, data);
    }, [data]);

    return (
        <Filter {...props}>
            <SearchInput source="query" alwaysOn/>
            <SelectInput source="status" choices={[
                {id: 'NEW', name: 'New'},
                {id: 'ACTIVATED', name: 'Activated'},
                {id: 'EXPIRED', name: 'Expired'}
            ]} />
            <BooleanInput source={"emailExist"}/>
            <SelectInput source="group" choices={data} disabled={loading}/>
        </Filter>
    );
};

const RowActionsButtonField = ({source = "", record = {}, clickCallback}) => (
    <span style={{display: "flex"}}>
        <span
            onClick={() => {
                clickCallback(record, ACTION_SEND_MAIL);
            }}
        >
            <VKeyListButton title={"Send code via email."}>
                <EmailIcon/>
            </VKeyListButton>
        </span>
        <span
            onClick={() => {
                clickCallback(record, ACTION_RE_GENERATE);
            }}
        >
            <VKeyListButton title={"Generate code again."}>
                <AutoRenewIcon/>
            </VKeyListButton>
        </span>
    </span>
);

const ActivationCodeList = (props) => {
    const {tokenPackId} = useParams<{ tokenPackId: string }>();
    const dataProvider = useDataProvider();
    const [tokenPack, setTokenPack] = useState<any>();
    const [record, setRecord] = useState<any>();
    const [action, setAction] = useState("");
    const [email, setEmail] = useState("");
    const refresh = useRefresh();
    const notify = useNotify();

    useEffect(() => {
        dataProvider
            .getOne("token-pack", {id: tokenPackId})
            .then(({data}) => {
                setTokenPack(data);
            });
    }, []);

    const [sendMail, sendMailState] = useMutation(
        {
            type: ACTION_SEND_MAIL,
            resource: "activation-code",
            payload: {data: {ids: [record?.id], email}},
        },
        {
            onSuccess: (response) => {
                notify("The activation code is sent successfully.");
                reset();
            },
            onFailure: (error) => {
                notify(JSON.stringify(error), "warning");
                reset();
            },
        }
    );

    const [reGenerate, reGenerateState] = useMutation(
        {
            type: ACTION_RE_GENERATE,
            resource: "activation-code",
            payload: {data: {ids: [record?.id], tokenPackId}},
        },
        {
            onSuccess: (response) => {
                notify("Re-generate code successfully.");
                reset();
                refresh();
            },
        }
    );

    const reset = () => {
        setEmail("");
        setRecord(null);
        setAction("");
    };

    useEffect(() => {
        switch (action) {
            case ACTION_SEND_MAIL:
                log.info("Send mail", record);
                emailDialog?.current?.show(record?.email || "");
                setAction("");
                break;

            case ACTION_RE_GENERATE:
                if (record) {
                    confirmDialog?.current?.setContent(
                        "Re-generate select activation code.",
                        `Re-generate activation code for user (${record.username})`
                    );
                    confirmDialog?.current?.show();
                }
                setAction("");
                break;
        }
    }, [record, action]);

    useEffect(() => {
        if (email !== "") {
            sendMail();
            setEmail("");
        }
    }, [email]);

    const wrapper = createRef<DashboardPageWrapperRef>();
    const emailDialog = createRef<EmailFormDialogRef>();
    const confirmDialog = createRef<ConfirmDialogRef>();

    useEffect(() => {
        wrapper?.current?.loading(
            sendMailState.loading || reGenerateState.loading
        );
    }, [sendMailState.loading, reGenerateState.loading]);

    return (
        <DashboardPageWrapperWithTheme
            title={tokenPack?.name + " - Activation Code"}
            ref={wrapper}
            loading={false}
        >
            <List
                basePath={"/activation-code"}
                resource={"activation-code"}
                actions={
                    <ACListActions
                        callback={(bLoad) => {
                            wrapper?.current?.loading(bLoad);
                        }}
                    />
                }
                filters={<Filters/>}
                filter={{tokenPackId: tokenPackId}}
                empty={false}
                bulkActionButtons={
                    <ActivationCodeBulkActions
                        callback={(bLoad) => {
                            wrapper?.current?.loading(bLoad);
                        }}
                    />
                }
                pagination={
                    <Pagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    />
                }
            >
                <Datagrid optimized>
                    <TextField source="code"/>
                    <TextField source="username"/>
                    <TextField source="email"/>
                    <TextField source="distinguishedName"/>
                    <TextField source="status"/>
                    <DateField
                        source="lastModifiedDate"
                        locales="vi-VN"
                        showTime={true}
                        label={"Generated date"}
                    />
                    <DateField
                        source="expireDate"
                        locales="vi-VN"
                        showTime={true}
                    />
                    <RowActionsButtonField
                        clickCallback={(record, action) => {
                            setRecord(record);
                            setAction(action);
                        }}
                    />
                </Datagrid>
            </List>
            <EmailFormDialog
                okCallback={(email) => {
                    log.warn("Sending email to ", email);
                    setEmail(email);
                }}
                ref={emailDialog}
            />
            <ConfirmDialog okCallback={() => reGenerate()} ref={confirmDialog}/>
        </DashboardPageWrapperWithTheme>
    );
};

export default ActivationCodeList;
